//
// Pluxee Variables

// Color palettes
// https://zeroheight.com/1f265dd57/v/latest/p/46dc59-color-palettes/b/985bd9/t/051e8e

// Deep blue variations
$color-deep-blue-12: #221C46;
$color-deep-blue-11: #2D264E;
$color-deep-blue-10: #362F54;
$color-deep-blue-9:  #463F5F;
$color-deep-blue-8:  #5A5469;
$color-deep-blue-7:  #716E7C;
$color-deep-blue-6:  #908C99;
$color-deep-blue-5:  #B5B2BC;
$color-deep-blue-4:  #D1CFD7;
$color-deep-blue-3:  #E5E3EA;
$color-deep-blue-2:  #F0EEF5;
$color-deep-blue-1:  #FAF8FF;

// Ultra green variations
$color-ultra-green-12: #012F0D;
$color-ultra-green-11: #00330E;
$color-ultra-green-10: #003D13;
$color-ultra-green-9:  #004F1A;
$color-ultra-green-8:  #006825;
$color-ultra-green-7:  #008A34;
$color-ultra-green-6:  #01AD43;
$color-ultra-green-5:  #01D253;
$color-ultra-green-4:  #00EB5E;
$color-ultra-green-3:  #85FD96;
$color-ultra-green-2:  #C7FCCA;
$color-ultra-green-1:  #DAFCDB;

// Boldy blue variations
$color-boldy-blue-12: #062C37;
$color-boldy-blue-11: #06303C;
$color-boldy-blue-10: #043948;
$color-boldy-blue-9:  #034A5D;
$color-boldy-blue-8:  #06637A;
$color-boldy-blue-7:  #0A83A1;
$color-boldy-blue-6:  #0FA4C9;
$color-boldy-blue-5:  #17CCF9;
$color-boldy-blue-4:  #88DDFB;
$color-boldy-blue-3:  #C3EBFB;
$color-boldy-blue-2:  #DEF3FB;
$color-boldy-blue-1:  #E8F6FC;

// Very yellow variations
$color-very-yellow-12: #2F2700;
$color-very-yellow-11: #332A00;
$color-very-yellow-10: #3D3300;
$color-very-yellow-9:  #4F4200;
$color-very-yellow-8:  #685800;
$color-very-yellow-7:  #8A7501;
$color-very-yellow-6:  #AD9400;
$color-very-yellow-5:  #D2B301;
$color-very-yellow-4:  #F0CD01;
$color-very-yellow-3:  #FFDC37;
$color-very-yellow-2:  #FDEEC1;
$color-very-yellow-1:  #FDF3D6;

// Confidently coral variations
$color-confidently-coral-12: #530512;
$color-confidently-coral-11: #5A0514;
$color-confidently-coral-10: #6B0318;
$color-confidently-coral-9:  #890021;
$color-confidently-coral-8:  #B2002E;
$color-confidently-coral-7:  #E9003F;
$color-confidently-coral-6:  #FF7375;
$color-confidently-coral-5:  #FF9895;
$color-confidently-coral-4:  #FCC1BE;
$color-confidently-coral-3:  #FBDDDA;
$color-confidently-coral-2:  #FCECEA;
$color-confidently-coral-1:  #FCF1F0;

// Neutrals
$color-slate-gray-12: #131117;
$color-slate-gray-11: #2B2B2D;
$color-slate-gray-10: #343335;
$color-slate-gray-9:  #444345;
$color-slate-gray-8:  #5A595C;
$color-slate-gray-7:  #777779;
$color-slate-gray-6:  #969698;
$color-slate-gray-5:  #B6B6B7;
$color-slate-gray-4:  #D0D0D1;
$color-slate-gray-3:  #E4E4E4;
$color-slate-gray-2:  #EFEFEF;
$color-slate-gray-1:  #F3F3F4;

// Green variants for success states
$color-dark-green-12: #042E20;
$color-dark-green-11: #043222;
$color-dark-green-10: #023C29;
$color-dark-green-9:  #004E36;
$color-dark-green-8:  #006245;
$color-dark-green-7:  #008861;
$color-dark-green-6:  #01AB7B;
$color-dark-green-5:  #00CF96;
$color-dark-green-4:  #31ECAE;
$color-dark-green-3:  #78FCC7;
$color-dark-green-2:  #C3FCE1;
$color-dark-green-1:  #D7FCEA;

// Orange variants for warning states
$color-orange-12: #3C210A;
$color-orange-11: #41230A;
$color-orange-10: #4F2A0A;
$color-orange-9:  #67360B;
$color-orange-8:  #874810;
$color-orange-7:  #B26019;
$color-orange-6:  #D47420;
$color-orange-5:  #F8A05B;
$color-orange-4:  #F8C5A0;
$color-orange-3:  #F9DFCD;
$color-orange-2:  #FAEDE3;
$color-orange-1:  #FBF2EC;

// Red variants for error states
$color-dark-red-12: #4C1304;
$color-dark-red-11: #531303;
$color-dark-red-10: #661101;
$color-dark-red-9:  #870A00;
$color-dark-red-8:  #B30000;
$color-dark-red-7:  #EA0101;
$color-dark-red-6:  #FF5D3B;
$color-dark-red-5:  #FF9A7E;
$color-dark-red-4:  #FCC2B1;
$color-dark-red-3:  #FBDDD4;
$color-dark-red-2:  #FCECE7;
$color-dark-red-1:  #FCF1EE;

// Blue variants for warning
$color-blue-12: #0F2365;
$color-blue-11: #0F266D;
$color-blue-10: #0F2D83;
$color-blue-9:  #123BA7;
$color-blue-8:  #1B51DC;
$color-blue-7:  #526CF8;
$color-blue-6:  #878DFA;
$color-blue-5:  #B1B0FB;
$color-blue-4:  #CFCCFA;
$color-blue-3:  #E3E2FA;
$color-blue-2:  #EFEEFB;
$color-blue-1:  #F3F3FC;

// Fushia
$color-fushia-1: #CC1480;

//
// Color use for Branding
// System: https://zeroheight.com/1f265dd57/v/latest/p/46dc59-color-palettes/b/31c5dd/i/224470248

// Color primaries & variations
$color-brand-primary:            $color-deep-blue-12;
$color-brand-primary-dark:       $color-deep-blue-9;
$color-brand-primary-medium:     $color-deep-blue-8;
$color-brand-primary-light:      $color-deep-blue-5;
$color-brand-primary-dimmed:     $color-deep-blue-1;
$color-brand-secondary:          $color-ultra-green-4;
$color-brand-secondary-dark:     $color-ultra-green-12;
$color-brand-secondary-light:    $color-ultra-green-3;
$color-brand-secondary-dimmed:   $color-ultra-green-2;
$color-brand-tertiary:           $color-boldy-blue-5;
$color-brand-tertiary-dark:      $color-boldy-blue-12;
$color-brand-tertiary-light:     $color-boldy-blue-4;
$color-brand-tertiary-dimmed:    $color-boldy-blue-2;
$color-brand-quaternary:         $color-very-yellow-3;
$color-brand-quaternary-dark:    $color-very-yellow-12;
$color-brand-quaternary-light:   $color-very-yellow-2;
$color-brand-quaternary-dimmed:  $color-very-yellow-1;
$color-brand-quinary:            $color-confidently-coral-6;
$color-brand-quinary-dark:       $color-confidently-coral-12;
$color-brand-quinary-medium:     $color-confidently-coral-7;
$color-brand-quinary-light:      $color-confidently-coral-4;
$color-brand-quinary-dimmed:     $color-confidently-coral-3;

// Color status and variations
$color-status-primary:           $color-blue-8;
$color-status-primary-dark:      $color-blue-12;
$color-status-primary-medium:    $color-blue-9;
$color-status-primary-light:     $color-blue-1;
$color-status-success:           $color-dark-green-8;
$color-status-success-dark:      $color-dark-green-12;
$color-status-success-light:     $color-dark-green-1;
$color-status-warning:           $color-orange-7;
$color-status-warning-dark:      $color-orange-8;
$color-status-warning-light:     $color-orange-1;
$color-status-error:             $color-dark-red-7;
$color-status-error-dark:        $color-dark-red-8;
$color-status-error-light:       $color-dark-red-1;

// Neutrals
$color-neutral:                  $color-slate-gray-8;
$color-neutral-dark:             $color-slate-gray-12;
$color-neutral-medium:           $color-slate-gray-9;
$color-neutral-light:            $color-slate-gray-5;
$color-neutral-dimmed:           $color-slate-gray-1;

// Backgrounds
$color-background-primary:       $color-deep-blue-1;
$color-background-disabled:      $color-slate-gray-2;
$color-background-info:          $color-blue-1;

// Borders
$color-border-primary:           $color-deep-blue-4;
$color-border-disabled:          $color-deep-blue-4;
$color-border-info:              $color-deep-blue-4;

// Highlight
$color-highlight:                $color-fushia-1;

// Text
$color-text-primary:             $color-deep-blue-12;
$color-text-secondary:           $color-deep-blue-9;
$color-text-tertiary:            $color-deep-blue-8;
$color-text-disabled:            $color-deep-blue-5;
