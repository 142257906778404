@font-face {
  font-family: 'TT Travels';
  src: url('~src/assets/fonts/tttravels/TTTravels-BlackItalic.woff2') format('woff2'),
    url('~src/assets/fonts/tttravels/TTTravels-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'TT Travels';
  src: url('~src/assets/fonts/tttravels/TTTravels-Black.woff2') format('woff2'),
    url('~src/assets/fonts/tttravels/TTTravels-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Travels';
  src: url('~src/assets/fonts/tttravels/TTTravels-DemiBoldItalic.woff2') format('woff2'),
    url('~src/assets/fonts/tttravels/TTTravels-DemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'TT Travels';
  src: url('~src/assets/fonts/tttravels/TTTravels-Bold.woff2') format('woff2'),
    url('~src/assets/fonts/tttravels/TTTravels-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Travels';
  src: url('~src/assets/fonts/tttravels/TTTravels-ExtraBoldItalic.woff2') format('woff2'),
    url('~src/assets/fonts/tttravels/TTTravels-ExtraBoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'TT Travels';
  src: url('~src/assets/fonts/tttravels/TTTravels-DemiBold.woff2') format('woff2'),
    url('~src/assets/fonts/tttravels/TTTravels-DemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Travels';
  src: url('~src/assets/fonts/tttravels/TTTravels-BoldItalic.woff2') format('woff2'),
    url('~src/assets/fonts/tttravels/TTTravels-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'TT Travels';
  src: url('~src/assets/fonts/tttravels/TTTravels-Italic.woff2') format('woff2'),
    url('~src/assets/fonts/tttravels/TTTravels-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'TT Travels';
  src: url('~src/assets/fonts/tttravels/TTTravels-ExtraLightItalic.woff2') format('woff2'),
    url('~src/assets/fonts/tttravels/TTTravels-ExtraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'TT Travels';
  src: url('~src/assets/fonts/tttravels/TTTravels-Light.woff2') format('woff2'),
    url('~src/assets/fonts/tttravels/TTTravels-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Travels';
  src: url('~src/assets/fonts/tttravels/TTTravels-ExtraBold.woff2') format('woff2'),
    url('~src/assets/fonts/tttravels/TTTravels-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Travels';
  src: url('~src/assets/fonts/tttravels/TTTravels-ExtraLight.woff2') format('woff2'),
    url('~src/assets/fonts/tttravels/TTTravels-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Travels';
  src: url('~src/assets/fonts/tttravels/TTTravels-LightItalic.woff2') format('woff2'),
    url('~src/assets/fonts/tttravels/TTTravels-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'TT Travels';
  src: url('~src/assets/fonts/tttravels/TTTravels-Regular.woff2') format('woff2'),
    url('~src/assets/fonts/tttravels/TTTravels-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Travels';
  src: url('~src/assets/fonts/tttravels/TTTravels-Medium.woff2') format('woff2'),
    url('~src/assets/fonts/tttravels/TTTravels-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TT Travels';
  src: url('~src/assets/fonts/tttravels/TTTravels-MediumItalic.woff2') format('woff2'),
    url('~src/assets/fonts/tttravels/TTTravels-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'TT Travels';
  src: url('~src/assets/fonts/tttravels/TTTravels-ThinItalic.woff2') format('woff2'),
    url('~src/assets/fonts/tttravels/TTTravels-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'TT Travels';
  src: url('~src/assets/fonts/tttravels/TTTravels-Thin.woff2') format('woff2'),
    url('~src/assets/fonts/tttravels/TTTravels-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}