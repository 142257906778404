.form-group {
  margin-bottom: $spacer;
}

.form-group label {
  margin-bottom: 4px;
  @include font-size(14px);
  font-weight: 600;
  letter-spacing: -0.04375rem;
}

.form-control {
  @include font-size(16px);
  border-radius: 0;
  text-shadow: none !important;
  font-weight: 600;
  letter-spacing: -0.05rem;

  &.ng-invalid.ng-dirty {
    border-color: $color-danger;
    background-color: $color-dark-red-1;  
  }
}

.invalid-feedback {
  display: block;
  @include font-size(14px);

  svg {
    position: relative;
    top: -2px;
    margin-right: 0.1rem;
    width: 16px;
    height: 16px;

    path {
      fill: $color-danger;
    }
  }
  .hidden-field {
    visibility: hidden;
  }
}
