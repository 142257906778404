// Pluxee Variables
@import 'plx-variables';

// Colors
$color-black:       #000;
$color-white:       #fff;

$color-primary:    $color-ultra-green-4;
$color-secondary:  $color-blue-8;
$color-danger:     $color-dark-red-8;

$primary:      $color-primary;
$secondary:    $color-secondary;
$danger:       $color-danger;

// Fonts
$font-family-sans-serif: 'TT Travels', 'TT Travels Trl', 'Noto Sans', Helvetica, Arial, sans-serif;
$font-family-alt: 'TT Travels', 'TT Travels Trl', 'Noto Sans', Helvetica, Arial, sans-serif;
$font-family-base: $font-family-sans-serif !default;
$headings-color:              $color-deep-blue-12;
$headings-font-family:   $font-family-alt;

$font-size-base: 1rem;

$body-color:   $color-deep-blue-12;

$link-color:   $color-blue-8;

$font-size-42: $font-size-base * 2.65;

// Border Radius
$border-radius: .25rem;

// Forms
$input-btn-padding-y:          .5rem;
$input-btn-focus-box-shadow:   none;
$input-border-color:           $color-deep-blue-4;
$input-focus-border-color:     $color-blue-8;
$input-placeholder-color:      $color-deep-blue-6;

$btn-disabled-opacity:         1;

// Grid
$grid-gutter-width:  2.5rem;

// Spacer
$spacer: 1rem;
$spacing-1x:  .5rem;
$spacing-2x:  1rem;
$spacing-3x:  1.5rem;
$spacing-4x:  2rem;
$spacing-6x:  3rem;
$spacing-8x:  4rem;
$spacing-10x: 5rem;
$spacing-12x: 8rem;

$spacers: (
	0: 0,
	1: $spacing-1x,
	2: $spacing-2x,
	3: $spacing-3x,
	4: $spacing-4x,
	5: $spacing-6x
);